<template>
  <van-nav-bar title="" left-text="返回" left-arrow @click-left="onClickLeft" />
  <div class="info">
    <p>
      第一，直接原则。直接原则是指个人信息原则上应该直接向本人收集。直接原则在OECD准则中称为“限制收集原则”。直接原则的另一个含义是，只有个人信息所有者本人才有权决定是否提供其个人信息。德国联邦个人资料保护法第13条第2项前句规定：“个人资料应该向当事人收集。”直接收集原则受到很多因素的限制。有学者认为，当向当事人收集资料不能或只能以不成比例昂贵费用，始可能为之时，或可能危及公务机关履行其任务或使其任务益形困难履行时，在此情形下，行政机关得于当事人不知悉情况下(秘密)向当事人收集资料，或向其他机关或第三人收集。
    </p>
    <p>
      第二，目的明确原则。目的明确原则是指个人信息在收集时必须有明确的特定目的，禁止超出目的范围收集、处理和利用个人信息。“特定目的”对国家机关来说就是根据行使职权、履行职责的需要处理和利用个人信息所具有的目的。
    </p>

    <p>
      第三，公开原则。公开原则是指对个人信息的收集、处理与利用一般应保持公开，本人有权知悉个人信息的收集与利用情况。“公开”并非指个人信息内容之公开，而系指个人信息搜集、储存、利用及提供等之公开。公开原则是OECD准则规定的资料保护原则之一。美国隐私权法第e条第3项、第4项与第8项均有通知当事人或应该进行公告的规定。
    </p>
    <p>
      第四，完整正确原则。完整正确原则是各国际组织和各国政府制定个人信息保护法普遍遵循的原则，具体指个人信息应该遵从其特定目的，在特定目的范围内必须保持完整、正确、及时更新。美国《隐私权法》第e条第5项规定：“行政机关在对任何人作决定时，其所运用的档案记录，均应保持正确、最新及完整，以使其在作出决定之时，能合理保证对该个人具有相当的公正性。”
    </p>
    <p>
      第五，限制利用原则。限制利用原则是指个人信息在利用时应该严格限定在收集的目的范围内，不应作收集目的之外使用。我国台湾《个人资料保护法》第6条规定：“个人资料的收集或利用，应尊重当事人之权益，依诚实及信用方法为之，不得逾越特定目的之必要范围。”
    </p>

    <p>
      第六，安全保护原则。安全保护原则是指个人信息应该处于安全的保护中，避免可能发生的个人信息的泄漏、意外灭失和不当使用。在个人信息的安全保护方面，德国的立法堪称典范。
    </p>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "",
  props: {},
  setup() {
    const router = useRouter();

    const onClickLeft = () => {
      router.go(-1);
    };
    return {
      onClickLeft,
    };
  },
};
</script>

<style>
.info {
  text-align: left;
  text-indent: 2em;
  padding: 0 30px 30px 30px;
}
</style>
